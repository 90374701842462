import React, { Component } from 'react';
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import Category from './category';
import List from './list/list';
import Two from './list/two';
import Large from './list/large';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Loader from '../general/loader';
import Subcategory from './subcategory';
import backArrow from '../../assets/backArrow.png'
import gridd from '../../assets/gridd.png';
import griddfull from '../../assets/griddfull.png';
import img8 from '../../assets/img8.png';
import menubg from '../../assets/menubg.jpg';


class Menu extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        categories: [],
        selectCategory: [],
        items: [],
        loadCat: false,
        load: false,
        listview: "large",
        subCategories: 0,
        isScrolled: false,
    }

    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
    }

    async componentDidMount(){

        // Get Items
        let id = -1; //window.location.pathname.replace("/menu/", "");

        // Get categories
        const {data} = await axios.get("https://manage.caviarmenu.com/api/categories/ninetynine");
        this.setState({ categories: data }, ()=>{
            this.getCategoryName(id);
        });

        {
            const {data} = await axios.get("https://manage.caviarmenu.com/api/items/ninetynine");
            this.setState({ items: data }, ()=>{
                this.setState({loadCat: true});
                this.setState({load: true});
            });
        }

        // this.layoutDirection();

        const rootElement = document.getElementById('menu-wraper');

        if (rootElement) {
            rootElement.addEventListener('scroll', this.handleScroll, true);
        }
        
    }

    componentWillUnmount() {
        const rootElement = document.getElementById('menu-wraper');

        if (rootElement) {
            rootElement.removeEventListener('scroll', this.handleScroll);
        }
    }
    

    layoutDirection() {
        if(this.props.Language != "en")
            document.getElementById("itemContainer").style.direction = "rtl";
        else
            document.getElementById("itemContainer").style.direction = "ltr";
    }

    getCategoryName(id) {
        if(id != -1)
            this.state.categories.map(cat => {
                if(cat.id == id) this.setState({ selectCategory: cat, subCategories: cat.subCategories[0]?.id });
            });
        else
            {
                let tempSubCategories = this.state.categories[0]?.subCategories[0]?.id?? null;
                this.setState({ selectCategory: this.state.categories[0], subCategories: tempSubCategories});
            }
    }

    handleChange = async e => {
        await this.props.handleLangaugeSubmit(e);
        // this.layoutDirection();
    }

    handleCategory = async catId => {
        this.setState({load: false});
        this.getCategoryName(catId);
        this.setState({load: true});
    }

    handleSubCategory = async catId => {
        this.setState({subCategories: catId});
    }

    handleListview = style => {
        this.setState({listview: style});
    }

    handleScroll() {
        const rootElement = document.getElementById('menu-wraper');
        const isScrolled = rootElement.scrollTop > 285;
    
        if (isScrolled !== this.state.isScrolled) {
          this.setState({ isScrolled });
        }

    }

    headCover = {'backgroundImage':`url('${menubg}')`, 'backgroundSize':'cover'}


    render() {

        const navbarClass = this.state.isScrolled ? 'sticky-navbar' : '';

        return (
            <div className='menu-items'>
                <div className="head" style={this.headCover} >
                    
                    
                    <img className='logo' src={this.settings.logo} alt="" />
                    <img className='vintage' src={img8} alt="" />
                </div>


                <div className={`${navbarClass}`}>

                    <div className='categories'>

                        <div>
                            {
                                this.state.loadCat ?
                                <> 
                                    { this.state.categories.map(cat => <Category key={cat.id}  Data = {cat} Language = {this.props.Language} handleCategory = { this.handleCategory} Active = {this.state.selectCategory.id == cat.id} ></Category>) }
                                </>
                                : <Loader></Loader>
                            }
                        </div>
                        
                    </div>

                    {
                        (this.state.loadCat && this.state.selectCategory.subCat)
                        ? 
                            <div className="subcategories">
                                {
                                    this.state.selectCategory?.subCategories?.map(
                                        cat => <Subcategory key={cat.id}  Data = {cat} Language = {this.props.Language} handleCategory = { this.handleSubCategory} Active = {this.state.subCategories == cat.id} />
                                    )
                                }
                            </div>
                        :
                        ""
                    }

                </div>

                <div className="head-listview">

                        <div className="language">
                            <div type='submit' className={this.props.Language == "en"? 'activ': ''} onClick={() => this.handleChange('en')}> English </div>
                            <div type='submit' className={this.props.Language == "ar"? 'activ': ''} onClick={() => this.handleChange('ar')}> العربیة </div>
                            <div type='submit' className={this.props.Language == "krd"? 'activ': ''} onClick={() => this.handleChange('krd')}> کوردی </div>
                        </div>

                        <div className="listview">
                            <img src={gridd} alt="" onClick={ () => this.handleListview("two")}/>
                            <img src={griddfull} alt="" onClick={ () => this.handleListview("large")}/>
                        </div>

                    </div>

                <div className='items' id='itemContainer'>
                    {
                        this.state.load ?
                        <> 
                            {
                                this.state.listview == "list" ? this.state.items[this.state.subCategories]?.map(item => <List key={item.id}  Data = {item} Language = {this.props.Language} ></List> )
                                : this.state.listview == "two" ? <div className='style-two'> { this.state.items[this.state.subCategories]?.map(item => <Two key={item.id}  Data = {item} Language = {this.props.Language} ></Two> ) } </div>
                                : <div className='style-large'> { this.state.items[this.state.subCategories]?.map(item => <Large key={item.id}  Data = {item} Language = {this.props.Language} ></Large> ) } </div>
                            }
                        </>
                        : <Loader></Loader>
                    }
                </div>

            </div>
        );
    }
}

export default Menu;